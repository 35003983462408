import React from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import config from '../config';
import loadingImg from "../assets/loading.gif"
import { toDate } from 'date-fns';
import { format } from 'date-fns';

const SystemChargesReport = () => {
    const companyId = useSelector((state) => state.user.companyId);

    const [basketClientHolding, setBasketClientHolding] = useState([])
    const [err, setErr] = useState("")
    const [inputs, setInputs] = useState({
        basketId: null,
        clientCode: "",
        fromDate: null,
        toDate: null,
    })
    const [isLoading, setIsLoading] = useState(false)
    const [currentDate, setCurrentDate] = useState('')
    const [baskets, setBaskets] = useState([])

    const email = useSelector((state) => state.user.email);
    const navigate = useNavigate();
    useEffect(() => {
        if (email === null || email === "") {
            navigate("/")
        }
    })

    useEffect(() => {
        //getBaskets();
        const today = new Date();
        const dateString = today.toISOString().substring(0, 10);
        setCurrentDate(dateString);
        inputs.fromDate = dateString;
        handleSearch(null);
        //getCashLedger();
    }, [])
    const getBaskets = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/basket/v1");

            if (res.data)
                setBaskets(res.data)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    function getFormattedDate() {
        const now = new Date();
        const formattedDate = now.toISOString().split('T')[0]; // Format date to avoid invalid characters in file name
        return formattedDate;
    }

    const handleExport = (clientCode, basketId) => {
        const width = 400;
        const height = 400;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;
        const axiosConfig = {
            responseType: 'blob',
            headers: {
                Accept: 'Application/csv'
            }
        }
        const url = `${config.BL_API_URL + `/clientsystemcharge/v1/exportSystemCharges?fromDate=${inputs.fromDate}&toDate=${inputs.toDate}&companyId=${companyId}`}`;

        axios.get(url, axiosConfig).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `SystemCharges_${getFormattedDate()}.csv`);
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {
            console.log("error", error)
        }
        );
    }

    const handleClearSearch = async (e) => {

        //let clientCode = document.getElementById("client-Code");
        let fromDate = document.getElementById("fromDate");
        let toDate = document.getElementById("toDate");
        //clientCode.value = ""
        fromDate.value = ""
        toDate.value = ""
        setInputs({
            //clientCode: "",
            fromDate: null,
            toDate: null,
        });
        setBasketClientHolding([]);
    }

    const handleSearch = async (e) => {
        console.log("search")
        try {
            setIsLoading(true);
            //const res = await axios.post(config.BL_API_URL + "/clientsystemcharge/v1/getsystemcharges", inputs);
            //const res = await axios.post(`${config.BL_API_URL}/clientsystemcharge/v1/getsystemcharges?fromDate=${encodeURIComponent(inputs.fromDate)}&toDate=${encodeURIComponent(inputs.toDate)}`;
            const res = await axios.get(config.BL_API_URL + `/clientsystemcharge/v1/getsystemcharges?fromDate=${inputs.fromDate}&toDate=${inputs.toDate}&companyId=${companyId}`);
            if (res.data) {
                setBasketClientHolding(res.data)
                setIsLoading(false);
            }
            else {
                setErr("backend error")
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }



    function formatNumber(value) {
        return new Intl.NumberFormat('en-IN').format(value);
    }

    function formatDate(value) {
        if (value == null || value === undefined || value === "") {
            return;
        }
        return new Intl.DateTimeFormat('en-IN').format(value);
    }
    const handleChange = (e) => {
        setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }


    return (
        <div className="my-12 md:px-14 p-4 max-w-screen-xl mx-auto ">
            <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">

                    <div className="border rounded-sm divide-y divide-gray-200 ">
                        <div className='col-span-5'>
                            <div className=" col-span-2">
                                <div className="py-3 px-4 flex justify-center items-center">
                                    <span className="text-gray-500 text-2xl font-bold">System Charges Report</span>
                                </div>
                                <div className='col-span-5 flex  gap-x-1 px-1 py-1 overflow-ellipsis'>
                                    <div className="grid grid-cols-5 gap-2 justify-start border-0 border-red-500">
                                        {/* <div className="col-span-1 gap-5 text-xs ">
                                            <fieldset className='border-2 text-xs border-gray-200 p-0'>
                                                <legend className='m-1 p-0'>Basket Name</legend>
                                                <select
                                                    onChange={handleChange}
                                                    name="basketId" id="basketId"
                                                    className="py-0 px-0 pe-0 block w-full focus:outline-none">
                                                    <option value="">-select-</option>
                                                    {baskets && (
                                                        baskets.map((basket, index) => <option key={index} value={basket.id}>{basket.name}</option>)
                                                    )}
                                                </select>
                                            </fieldset>
                                        </div>
                                        <div className="col-span-1 gap-5 text-xs  ">
                                            <fieldset className='border-2 text-xs border-gray-200 p-0'>
                                                <legend className='m-1 p-0'>Client Code</legend>
                                                <input id="client-Code" w-full name="clientCode" onChange={handleChange} placeholder='Search by client code' className=" py-0 px-0 pe-0 block focus:outline-none" />
                                            </fieldset>
                                        </div> */}

                                        <div className="col-span-1 gap-5 text-xs px-5 ">
                                            <fieldset className='border-2 text-xs border-gray-200 p-0 '>
                                                <legend className='m-1 p-0'>From Date</legend>
                                                <input
                                                    onChange={handleChange}
                                                    type="date" name="fromDate" id="fromDate" placeholder="fromDate"
                                                    className="py-0 px-0 pe-0 w-full block  focus:outline-none "
                                                />
                                            </fieldset>
                                        </div>
                                        <div className="col-span-1 gap-5 text-xs px-5 ">
                                            <fieldset className='border-2 text-xs border-gray-200 p-0 '>
                                                <legend className='m-1 p-0'>To Date</legend>
                                                <input
                                                    onChange={handleChange}
                                                    type="date" name="toDate" id="toDate" placeholder="toDate"
                                                    className="py-0 px-0 pe-0 w-full block  focus:outline-none "
                                                />
                                            </fieldset>
                                        </div>

                                        <div className="col-span-1 gap-1 text-center text-xs " >
                                            <button type="button" onClick={e => handleSearch()} className="py-2 px-2 inline-flex right-0 gap-x-1 text-sm font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">

                                                <span>Search</span>
                                                {isLoading &&
                                                    <img src={loadingImg} alt="Icon" className="w-6 text-left justify-start items-right" />
                                                }

                                            </button>
                                        </div>
                                        <div className="col-span-1 gap-1 text-center text-xs " >

                                            <button type="button" onClick={e => handleClearSearch()} className="py-2 px-2 inline-flex right-0 gap-x-1 text-sm font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">
                                                Clear Search

                                            </button>
                                        </div>
                                        <div className="col-span-1 gap-1 text-center text-xs " >

                                            <button type="button" onClick={e => handleExport()} className="py-2 px-2 inline-flex right-0 gap-x-1 text-sm font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">
                                                Export

                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overflow-hidden">
                            <div className="overflow-hidden">
                                <table className="min-w-full divide-y divide-gray-200  ">
                                    <thead className="bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">#</th>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Renewal Date</th>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Basket Name</th>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Client Code</th>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">AUM Value</th>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">System Charge</th>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Broker's System Charge</th>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Subscription Date</th>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Match</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {basketClientHolding &&

                                            basketClientHolding.map((holding, index) => (
                                                <tr key={index} className={`${index % 2 === 0 ? '' : 'bg-gray-100'} cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-200`}>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{index + 1}</td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{format(new Date(holding[5]), 'dd/MMM/yyyy')}</td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{holding[0]}</td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{holding[1]}</td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{formatNumber(holding[2])}</td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{formatNumber(holding[3])}</td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{formatNumber(holding[4])}</td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{format(new Date(holding[7]), 'dd/MMM/yyyy')}</td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{holding[6]}</td>
                                                </tr>
                                            ))}
                                        {(basketClientHolding === null || basketClientHolding.length <= 0) &&
                                            <tr><td colSpan={9} className='text-center text-red-500 '>No records to display!</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default SystemChargesReport;