import React from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FiUserPlus } from "react-icons/fi";
import { VscSave } from "react-icons/vsc";
import { FcCancel } from "react-icons/fc";
import { FaTrash } from "react-icons/fa";
import config from '../../config';
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import AutoComplete from "../Dashboard/AutoComplete";

const BasketSubscription = () => {
    const companyId = useSelector((state) => state.user.companyId);
    const name = useSelector((state) => state.user.name);

    const [baskets, setBaskets] = useState([])
    const [clients, setClients] = useState([])
    const [companies, setCompanies] = useState([])
    const [basketSubscribers, setBasketSubscribers] = useState([])
    const [err, setErr] = useState("")
    const [addOn, setAddOn] = useState(false)
    const [editingRow, setEditingRow] = useState(null)
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({
        companyId: companyId,
        basketId: "",
        clientId: "",
        companyName: "",
        basketName: "",
        enabled: ""
    })
    const [searchCriterion, setSearchCriterion] = useState({
        clientName: "",
        clientCode: "",
        basketId: null,
        enabled: null,
        companyId: null
    })

    useEffect(() => {
        if (name === null || name === "") {
            navigate("/")
        }

        //getBasketSubscribers(companyId);
        getCompanies(companyId);
        getBaskets(companyId);
        //handleSearch(companyId)
    }, [companyId])

    const handleSearch = async (e) => {
        try {
            /* if (e === null || e === undefined) {
                searchCriterion.companyId = 1
            }
            else
                searchCriterion.companyId = e; */
            searchCriterion.companyId = companyId;

            setBasketSubscribers(null)
            //const res = await axios.get(config.BL_API_URL + "/company/v1/getBasketSubscribers", { params: { companyId } });
            const res = await axios.post(config.BL_API_URL + "/company/v1/getBasketSubscribersByFilter", searchCriterion);

            //console.log(res.data)

            if (res.data)
                setBasketSubscribers(res.data)
            else
                setErr("backend error")

        } catch (err) {
            setBasketSubscribers(null)
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const handleAdd = () => {
        setAddOn(!addOn);
    }

    const handleCancel = () => {
        setAddOn(false);
    }
    const handleSave = async (e) => {
        try {
            console.log(inputs)
            const res = await axios.post(config.BL_API_URL + "/BasketClientMap/v1/addBasketSubscriber", inputs);

            if (res.status === 200) {
                setAddOn(false);
                handleSearch(companyId);
            }
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const handleChange = (e) => {
        if (e.target.name === "enabled") {
            const value = e.target.value === "0" ? false : true;
            setInputs(prev => ({ ...prev, [e.target.name]: value }))
        }
        else {
            setInputs(prev => ({ ...prev, [e.target.name]: e.target.value, [e.target.id]: e.target.value }))
        }
        if (addOn && e.target.name === "basketName") {
            getClients(e.target.value)
        }
    }

    const getBaskets = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/basket/v1/getBasketByCompanyId", { params: { companyId } });

            if (res.data) {
                setBaskets(res.data)
            }
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const getClients = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/client/v1/getAllUsers");//, {params: {basketId: e}});

            if (res.data)
                setClients(res.data)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const getCompanies = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/company/v1/getAllCompanies");//, {params: {companyId: companyId}});

            if (res.data)
                setCompanies(res.data)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const handleEnabledChangedChange = async (e) => {
        const value = e.target.value === "0" ? false : true;

        setBasketSubscribers(prevState => prevState.map(subscriber =>
            subscriber.clientId === editingRow
                ? { ...subscriber, enabled: value }
                : subscriber
        ));
        const editingSubscriber = basketSubscribers.find(subscriber => subscriber.clientId === editingRow);
        editingSubscriber.enabled = value;
        //console.log(editingSubscriber)
        try {
            const res = await axios.post(config.BL_API_URL + "/BasketClientMap/v1/addBasketSubscriber", editingSubscriber);

            if (res.status === 200) {
                setEditingRow(null);
                //getBasketSubscribers(companyId);
            }
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const handleEdit = async (e) => {
        setEditingRow(e);
    }

    const handleEditSave = async (e) => {
        setEditingRow(null)
    }

    const handleEditSaveCancel = async (e) => {
        setEditingRow(null)
    }

    const handleSearchCriterionChange = async (e) => {
        /*         if (e.target.name === "enabled") {
                    const value = e.target.value === "0" ? false : true;
                    setSearchCriterion(prev => ({ ...prev, [e.target.name]: value }))
                }
                else {
                    setSearchCriterion(prev => ({ ...prev, [e.target.name]: e.target.value, [e.target.id]: e.target.value }))
                } */
        setSearchCriterion(prev => ({ ...prev, [e.target.name]: e.target.value }))
        console.log(searchCriterion.basketId)
        console.log(searchCriterion.clientCode)
        console.log(searchCriterion.clientName)
        console.log(searchCriterion.enabled)
    }

    return (
        <div className="my-10 md:px-14 p-4 max-w-screen-xl mx-auto ">
            <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">

                    <div className="border rounded-lg divide-y divide-gray-200 ">
                        <div className="py-3 px-4 flex justify-center items-center">
                            <span className="text-gray-500 text-2xl font-bold">Basket Subscribers List</span>
                        </div>
                        <div className='flex items-end justify-center'>
                            <div className='flex-col '></div>
                            <div className='flex-1/2 m-1'>
                                <fieldset className='border-2 text-xs border-gray-200 p-0'>
                                    <legend className='m-1 p-0'>Basket Name</legend>
                                    <select
                                        onChange={handleSearchCriterionChange}
                                        name="basketId" id="basketId"
                                        className="block w-full focus:outline-none">
                                        <option value="">- Select -</option>
                                        {baskets && (
                                            baskets.map((basket, index) => <option key={basket.basketId} value={basket.basketId}>{basket.name}</option>)
                                        )}
                                    </select>
                                </fieldset>
                            </div>
                            <div className="flex-1/2 m-1">
                                <fieldset className='border-2 text-xs border-gray-200 p-0'>
                                    <legend className='m-1 p-0 height: "50px"'>Client Code</legend>
                                    <input type='text' id="client-Code" min-w-full w-full name="clientCode" onChange={handleSearchCriterionChange} placeholder='Enter Text'
                                        className=" flex:1 boxSizing: 'border-box' focus:outline-none" />
                                </fieldset>
                            </div>
                            <div className='flex-1/2 m-1'>
                                <fieldset className='border-2 text-xs border-gray-200 p-0'>
                                    <legend className='m-1 p-0'>Client Name</legend>
                                    <div className='relative'>
                                        <input id="client-Name" name="clientName" onChange={handleSearchCriterionChange} placeholder='Enter Text'
                                            className="py-0 px-0 pe-0 block focus:outline-none " />
                                        {/* <span className='absolute right-0 top-0 bottom-10 my-5 mt-2 mr-2 cursor-pointer'>x</span> */}
                                    </div>
                                </fieldset>
                            </div>
                            <div className='flex-1/2 m-1'>
                                <fieldset className='border-2 text-xs border-gray-200 p-0'>
                                    <legend className='m-1 p-0'>Active</legend>
                                    <select
                                        onChange={handleSearchCriterionChange}
                                        name="enabled" id="enabled"
                                        className="py-0 px-0 pe-0 w-full block focus:outline-none">
                                        <option value="">- Select -</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div className='flex-1/2 m-1'>
                                <button type="button" onClick={e => handleSearch()}
                                    className="py-2 px-4 inline-flex right-0 gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">Search</button>                            </div>
                            <div className='flex-1/2 m-1'>
                                <button type="button" onClick={e => handleSearch()}
                                    className="py-2 px-4 inline-flex right-0 gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">Clear Search</button>
                            </div>

                        </div>
                        <div className="overflow-hidden">
                            <div className="overflow-hidden">
                                <table className="min-w-full divide-y divide-gray-200  ">
                                    <thead className="bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="px-2 py-1 text-start text-xs font-medium text-gray-500 uppercase ">Entity Name</th>
                                            <th scope="col" className="px-2 py-1 text-start text-xs font-medium text-gray-500 uppercase">Basket Name</th>
                                            <th scope="col" className="px-2 py-1 text-xs text-start font-medium text-gray-500 uppercase w-[250px]">Client Code</th>
                                            <th scope="col" className="px-2 py-1 text-xs text-start font-medium text-gray-500 uppercase w-[250px]">Client Name</th>
                                            <th scope="col" className="px-2 py-1 text-xs text-start font-medium text-gray-500 uppercase w-[100px]">Active</th>
                                            <th scope="col" className="px-2 py-1 text-start text-xs font-medium text-gray-500 uppercase">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">
                                                {addOn &&
                                                    <select
                                                        onChange={handleChange}
                                                        name="companyName" id="companyId"
                                                        value={companyId}
                                                        disabled
                                                        className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                                        <option value="-">- Select -</option>
                                                        {companies && (
                                                            companies.map((company, index) => <option key={index} value={company.id}>{company.name}</option>)
                                                        )}
                                                    </select>
                                                }

                                            </td>
                                            <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">
                                                {addOn &&
                                                    <select
                                                        onChange={handleChange}
                                                        name="basketName" id="basketId"
                                                        className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                                        <option value="-">- Select -</option>
                                                        {baskets && (
                                                            baskets.map((basket, index) => <option key={basket.basketId} value={basket.basketId}>{basket.name}</option>)
                                                        )}
                                                    </select>
                                                }
                                            </td>
                                            <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">
                                                {/* <AutoComplete
                                                    setInputs={setInputs}
                                                    data={clients}
                                                    inputs={inputs}
                                                    editOn={false}
                                                /> */}
                                            </td>
                                            <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">
                                                {addOn &&
                                                    <select
                                                        onChange={handleChange}
                                                        name="clientName" id="clientId"
                                                        className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                                        <option value="-">- Select -</option>
                                                        {clients && (
                                                            clients.map((client, index) => <option key={index} value={client.id}>{client.name}</option>)
                                                        )}
                                                    </select>
                                                }

                                            </td>
                                            <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">
                                                {addOn &&
                                                    <select
                                                        onChange={handleChange}
                                                        name="enabled" id="enabled"
                                                        className="py-2 px-3 pe-9 block w-[100px] sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select>
                                                }
                                            </td>
                                            <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">
                                                {!addOn &&
                                                    < span onClick={e => handleAdd()} type="button"
                                                        className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                        <FiUserPlus className="text-base" />New Entry
                                                    </span>
                                                }
                                                {addOn &&
                                                    <>
                                                        <span onClick={e => handleCancel()} aria-disabled={addOn} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                            <FaTrash className="text-base" />Cancel
                                                        </span>

                                                        <span onClick={e => handleSave()} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                            <VscSave className="text-blue-600 text-base" />Save
                                                        </span>
                                                    </>
                                                }

                                            </td>
                                            <p className="text-red-400 mt-8 justify-center">
                                                {err}
                                            </p>
                                        </tr>
                                        {basketSubscribers &&
                                            basketSubscribers.map((basketSubscriber, index) => (
                                                <tr key={basketSubscriber.clientId} className={`${index % 2 === 0 ? '' : 'bg-gray-100'} cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-200`}>
                                                    <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">{basketSubscriber.companyName}</td>
                                                    <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">{basketSubscriber.basketName}</td>
                                                    <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 w-[250px] ">{basketSubscriber.clientCode}</td>
                                                    <td className="px-2 py-1 whitespace-nowrap text-sm uppercase text-gray-800 w-[250px] ">{basketSubscriber.clientName}</td>
                                                    <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 w-[100px]">
                                                        {editingRow !== basketSubscriber.clientId && (basketSubscriber.enabled ? "Yes" : "No")}
                                                        {editingRow === basketSubscriber.clientId &&

                                                            <select
                                                                value={basketSubscriber.enabled ? "1" : "0"}
                                                                onChange={handleEnabledChangedChange}
                                                                name="enabled"
                                                                id="enabled"
                                                                className="py-2 px-3 pe-9 block  sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                                                <option value="0">No </option>
                                                                <option value="1">Yes</option>
                                                            </select>
                                                        }

                                                    </td>
                                                    <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">
                                                        {editingRow !== basketSubscriber.clientId &&
                                                            <span onClick={e => handleEdit(basketSubscriber.clientId)} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                                <FiEdit className="text-base" />Edit
                                                            </span>
                                                        }
                                                        {editingRow === basketSubscriber.clientId &&
                                                            <>{/* <span onClick={() => handleEditSave()} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                                <VscSave className="text-blue-600 text-base" />Save
                                                            </span> */}
                                                                <span onClick={() => handleEditSaveCancel()} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                                    <FcCancel className="ml-2 text-red-600 text-base" />Cancel
                                                                </span></>


                                                        }
                                                    </td>
                                                </tr>
                                            ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default BasketSubscription;